<template>
  <div class="job">
    <el-row class="btns">
      <el-image
        @click="onToBack"
        :src="require(`@/assets/image/blueBack.png`)"
        class="backImg"
      ></el-image>
      <el-button type="primary" class="btn creactBtn" plain @click="onJobCreate"
        ><i class="el-icon-plus"></i>新建作业</el-button
      >
      <!-- <el-button
        type="primary"
        class="btn"
        plain
        @click="handleAllocationClassVisible = true"
        >导入作业</el-button
      > -->
    </el-row>
    <el-row class="workList">
      <el-col
        class="classItem"
        v-for="item in workList"
        :key="item.shop_user_entrepot_id"
        type="flex"
        justify="space-between"
      >
        <el-row class="itemTop" type="flex" justify="space-between">
          <el-col :span="24" class="entrepot_name">{{
            item.entrepot_name
          }}</el-col>
        </el-row>
        <div class="itemMainBox">
          <el-row class="itemMain">
            <el-col class="flex" :span="24">
              <el-button
                class="operateBtn"
                type="primary"
                @click="onEditWork(item.shop_user_entrepot_id)"
                >编辑</el-button
              >
              <el-button
                class="operateBtn"
                type="primary"
                @click="onPublishVisible(item.shop_user_entrepot_id)"
                >发布</el-button
              ></el-col
            >
            <el-col class="flex" :span="24">
              <div class="jobEndTime">
                创建时间：{{ conversion(item.created) }}
              </div>
              <el-button
                class="readOverBtn"
                type="primary"
                plain
                @click="openDeleteConfirm(item.shop_user_entrepot_id)"
                >删除</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="发布" :visible.sync="publishVisible" width="50%">
      <el-form :model="form">
        <el-form-item label="所属班级" :label-width="formLabelWidth">
          <el-select v-model="form.shop_user_class_id" placeholder="请选择班级">
            <el-option
              v-for="item in classList"
              :label="item.class_title"
              :value="item.shop_user_class_id"
              :key="item.shop_user_class_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有效时段" :label-width="formLabelWidth">
          <el-col :span="8">
            <el-date-picker
              type="date"
              placeholder="开始日期"
              v-model="form.start_time"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="8"
            ><el-date-picker
              type="date"
              placeholder="结束日期"
              v-model="form.end_time"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="publishVisible = false">取 消</el-button>
        <el-button type="primary" @click="onPublishEntrepot()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAdminWorkList,
  deleteEntrepot,
  publishEntrepot,
  getAdminClassList,
} from "@/request/api";
// import UploadModal from '@/components/UploadModal.vue'

export default {
  // components: {
  //   UploadModal
  // },
  data() {
    return {
      workList: [], // 作业列表
      classList: [], // 班级列表
      formLabelWidth: "100px",
      publishVisible: false, // 发布弹窗控制
      shop_user_entrepot_id: "", // 当前选择项
      form: {
        shop_user_class_id: "", // 班级id
        start_time: "",
        end_time: "",
      },
    };
  },
  methods: {
    async init() {
      const res = await getAdminWorkList();
      if (res.status === 200) {
        this.workList = res.data.data;
      }
    },
    // 新建作业
    onJobCreate() {
      this.$router.push("jobCreact");
    },
    // 编辑作业
    onEditWork(shop_user_entrepot_id) {
      this.$router.push({
        path: "/aiClass/jobCreact",
        query: { shop_user_entrepot_id },
      });
    },
    onPublishVisible(shop_user_entrepot_id) {
      this.shop_user_entrepot_id = shop_user_entrepot_id;
      getAdminClassList().then((res) => {
        if (res.status === 200) {
          this.classList = res.data.data;
          this.publishVisible = true;
        }
      });
    },
    async onPublishEntrepot() {
      const body = {
        ...this.form,
        start_time: this.conversion(this.form.start_time),
        end_time: this.conversion(this.form.end_time),
        shop_user_entrepot_id: this.shop_user_entrepot_id,
        isNew: true,
      };
      const res = await publishEntrepot(body);
      if (res.data.code === "1000") {
        this.$message.success("发布成功");
        this.publishVisible = false;
        this.init();
      } else {
        this.$message.success(res.data.msg);
      }
    },
    async onDeleteWork(shop_user_entrepot_id) {
      const res = await deleteEntrepot({ shop_user_entrepot_id });
      if (res.data.code === "1000") {
        this.$message.success("删除成功");
        this.init();
      } else {
        this.$message.success(res.data.msg);
      }
    },
    openDeleteConfirm(shop_user_entrepot_id) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDeleteWork(shop_user_entrepot_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onAllocationClassSubmit() {
      console.log(this.selectClassForm, "selectClassForm");
    },

    onToBack() {
      this.$router.back();
    },

    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.job {
  .line {
    text-align: center;
  }
  .btns {
    display: flex;
    align-items: center;
    margin: 41px 0px 41px 51px;
    .backImg {
      width: 40px;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn {
      width: 149px;
      line-height: 17px;
      font-size: 20px;
      border-radius: 10px;
    }
    /deep/ .el-input__inner {
      height: 41px;
      line-height: 41px;
      font-size: 20px;
      width: 216px;
    }
    .creactBtn {
      background: #91d883;
      border: 1px solid #91d883;
      color: #fff;
      margin-right: 48px;
    }
  }
  .workList {
    // margin-left: 60px;
    .classItem {
      width: 458px;
      height: 273px;
      margin-left: 51px;
      margin-bottom: 60px;
      .itemTop {
        // height: 58px;
        border-radius: 10px 10px 0px 0px;
        background: #91d883;
        color: #fff;
        font-size: 24px;
        line-height: 48px;
      }
      .entrepot_name {
        margin-left: 20px;
      }
      .topIcon {
        margin-right: 20px;
        line-height: 48px;
        text-align: right;
        cursor: pointer;
      }
      .itemMainBox {
        border: 1px solid #91d883;
        border-radius: 0px 0px 10px 10px;
        background: #fff;
        border-top: 0px;
        margin: 0 auto;
        padding: 26px 20px;
      }
      .itemMain {
        width: 418px;
        height: 154px;
        padding: 20px;
        background: #f2f7ff;
        border-radius: 10px 10px 10px 10px;
        .classTitle {
          display: flex;
          // text-align: left;
          // justify-content: left;
        }
        .className {
          line-height: 39px;
          margin-left: 15px;
        }
        .checkJob {
          font-size: 16px;
          margin-top: 20px;
        }
        .jobEndTime {
          margin-left: 15px;
          margin-top: 28px;
          font-size: 15px;
        }
        .operateBtn {
          width: 128px;
          line-height: 11px;
          margin-top: 20px;
          margin-left: 35px;
        }
        .readOverBtn {
          margin-top: 20px;
          width: 128px;
          line-height: 11px;
          margin-left: 25px;
        }
      }
      .mainImg {
        width: 39px;
        height: 39px;
      }
      .mainNumBox {
      }
      .mainNumTitle {
        font-size: 20px;
      }
      .mainNum {
        font-size: 22px;
        color: #0066ff;
      }
    }
  }
}
</style>
